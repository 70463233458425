import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Vi har nedan samlat ihop alla free spins för idag.`}</p>
    <h3>{`Unibet – Free spins för mål och medaljer`}</h3>
    <p>{`Hos Unibet så får man idag rejält med free spins då Sverige vann emot Slovenien med 5-0 i ishockey så innebär det att alla får 5 free spins på South Park och då Sverige även vann två brons igår så blir det ytterligare 6 free spins i Gonzo Quest`}</p>
    <p>{`Hämta 11 free spins hos Unibet – Spela nu!`}</p>
    <h3>{`Bertil – 20 free spins i mobilen`}</h3>
    <p>{`Bertil har bra erbjudande fortfarande men endast tre dagar till. Erbjudandet är 20 free spins i mobilen varje dag hela veckan tills den 23 februari hos Bertil. För att ta del av erbjudandet så krävs det att man gör en insättning på minst 100kr.`}</p>
    <p>{`Hämta 20 free spins – Spela nu!`}</p>
    <h3>{`Paf – 30 free spins`}</h3>
    <p>{`Även Paf ger free spins när Sverige skördar framgångar i OS och med de senaste dagarna framgångar så slår dom på stort och ger alla spelare 30 free spins på Starburst, dessa free spins går även att ta del av i mobilen.`}</p>
    <p>{`Hämta 30 free spins på Starburst – Spela nu!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      